import axios from 'axios';


const instance = axios.create({
    // baseURL: 'https://squintbox-api.dev.node-production.com/squintbox-api'
    baseURL: 'https://admin.squint-box.com/squintbox-api'
});

if(localStorage.getItem('token')  ){

  let token = JSON.parse(localStorage.getItem('token'));


  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  instance.defaults.headers.post['Content-Type'] = 'application/json';
} 

export default instance;