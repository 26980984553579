import React, {useEffect} from 'react';
import classes from './HomePage.module.scss';
import SBTracking from '../../services/tracking'
import Hero from '../Hero/Hero';

const HomePage = () => {

    useEffect(() => {
        let pageViewData = {
            page_category:'Welcome',
            page_name:'App - Welcome'
        }
        SBTracking.pageView(pageViewData);
    },[])

    return(
        <div className={classes.HomePage}>
            <Hero />
        </div>
    )
}

export default HomePage;